<style lang="sass">
</style>

<template>
    <section v-show="isActive"
             :aria-hidden="!isActive"
             :id="hash"
             role="tabpanel"
    >
        <slot v-if="!content" />
        <div v-if="content" v-html="content"></div>
    </section>
</template>

<script>
    export default {
        props: {
            id: { default: null },
            name: { required: true },
            prefix: { default: '' },
            suffix: { default: '' },
            isDisabled:{ default: false },
            hidden:{ default: false }
        },
        data: () => ({
            isActive: false,
            isVisible: true,
            content: ''
        }),
        computed: {
            header() {
                return this.prefix + this.name + this.suffix;
            },
            hash() {
                if (this.isDisabled) {
                    return '#';
                }
                return this.id ?
                    '#' + this.id :
                    '#' + this.name.toLowerCase().replace(/ /g, '-');
            },
        },
    };
</script>