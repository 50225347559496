// Should be vue/dist/vue.min for production
let Vue = require( 'vue/dist/vue' );
let axios = require( 'axios' );
let LiveScreen = require( './components/LiveScreen.vue' );
let Tab = require( './components/Tab.vue' );
let Tabs = require( './components/Tabs.vue' );
let mfp = require( 'magnific-popup' );
let jQuery = require( 'jquery' );
let Slideout = require( 'slideout' );
let Particles = require( 'particles.js' );
let Select2 = require ( "select2" )($);
window.ListJs = require( 'list.js' );

// Require other includes
require( './includes/PerspectiveTransform.js' );
require( './includes/_jQuery.printElement.js' );

Vue.component( 'live-screen', LiveScreen );
Vue.component( 'tab', Tab );
Vue.component( 'tabs', Tabs );

var els = document.getElementsByClassName( "vue-container" );

Array.prototype.forEach.call( els, function( el ) {
    let VueInstance = new Vue( {
        el: '#' + el.id,
    } );
} );

//require( './includes/_homepageTimeline.js' );

/**
 * Setup custom coupon code showing script
 */
jQuery( ".custom_showcoupon" ).on( "click", function( e ) {
    e.preventDefault();
    jQuery( ".checkout_coupon" ).toggle();
} );

jQuery( document ).ready( function( $ ) {
    var resizeTimer;

    // Update location of update license link, and show it
    var $upgrade_link = $( '.edd-sl-upgrade-link a' );
    if ( $upgrade_link.length ) {
        $upgrade_link.each( function() {
            var original_url = $( this ).attr( 'href' );
            var new_url = '/my-account/#endpoint-' + original_url.replace( /^.*\/\/[^\/]+/, '' );
            $( this ).attr( 'href', new_url ).closest( '.edd-sl-upgrade-link' ).css( 'display', 'block' );
        } );
    }

    $( '#edd_sl_show_renewal_form' ).text( "Renew license" );
    $( '#edd_sl_renewal_form' ).show();

    $( '.edd_discount_link' ).text( "Enter Code" );
    $( '#edd_discount_code' ).css( 'opacity', '1' );

    $('#edd-license-key').on( 'input', function(e) {
        var input  = $('#edd-license-key');
        var button = $('#edd-add-license-renewal');

        if ( input.val() != '' ) {
            button.prop("disabled", false);
        } else {
            button.prop("disabled", true);
        }
    });

    // Make EDD tables responsive
    var $tables = $( '.edd-table, .edd_sl_table, #edd_user_history, #edd_subscription_receipt' ).not( '#edd_purchase_receipt' );
    $tables.each( function() {
        var $tds = $( this ).find( 'td' );
        $tds.each( function() {
            var $td = $( this );
            var $th = $td.closest('table').find('th').eq($td.index());
            $td.attr( 'data-label', $th.text() );
        } );
    } );

    $( '#edd_user_history .edd_license_key a' ).attr( 'href', '#licenses' );

    $( '.log-in-reminder ~ .woocommerce-form-login' ).attr( 'id', 'login-popup-form' );

    $( 'body' ).on( 'submit', '#edd_sl_license_add_site_form', function( e ) {
        e.preventDefault();
        var $form = $(this).closest("form");
        var site = $form.find( '[name="site_url"]' ).val();
        var model = $form.find( 'input[name!=_wp_http_referer]' ).serialize();

        var isValidURL = /^(?:(?:https?|ftp):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test( site );
        if ( !isValidURL ) {
            $form.find( 'input[type=text]' ).addClass( 'error-field' );
            return false;
        }

        console.log( model );

        $form.find( 'input[type=submit]' ).val( 'Adding...' );

        $.ajax({
            url: '/',
            type: "POST",
            data: model,
            success: function (data) {
                var updatedHash = window.location.hash.replace( '&edd_action=deactivate_site', '' ) + '&instance=' + Math.floor( Math.random() * 9999999 );
                window.location.hash = updatedHash;
            },
            error: function (error) {
                return false;
            },
            complete: function() {
                $form.find( 'input[type=submit]' ).val( 'Add Site' );
            }
        });

        return false;
    } )

    $( '#login-popup' ).magnificPopup( {
        type: 'inline',
        preloader: false,
        focus: '#username',

        // When elemened is focused, some mobile browsers in some cases zoom in
        // It looks not nice, so we disable it:
        callbacks: {
            beforeOpen: function() {
                if ( $( window ).width() < 768 ) {
                    this.st.focus = false;
                } else {
                    this.st.focus = '#username';
                }
            }
        }
    } );

    $( '.print-div' ).on( 'click', function( e ) {
        e.preventDefault();
        let $div = $( '#' + $( this ).attr( 'data-div-id' ) );
        $div.printArea();
    } );

    var slideout = new Slideout( {
        'panel': document.getElementById( 'panel' ),
        'menu': document.getElementById( 'menu' ),
        'duration': 400,
        'padding': 240,
        'tolerance': 70,
        'side': 'right',
        'touch': false
    } );

    $( '.mm-trigger' ).on( 'click', function() {
        $( this ).toggleClass( 'active' );
        slideout.toggle();
    } );

    $( '.mfp-inline-popup' ).magnificPopup({
        type: 'inline',
        preloader: false,
        focus: '[data-focus="true"]',
        removalDelay: 300,
        mainClass: 'my-mfp-zoom-in',

        // When elemened is focused, some mobile browsers in some cases zoom in
        // It looks not nice, so we disable it:
        callbacks: {
            beforeOpen: function() {
                if($(window).width() < 700) {
                    this.st.focus = false;
                } else {
                    this.st.focus = '[data-focus="true"]';
                }
            }
        }
    });


    /**
     * Select 2
     */

    $( window ).resize( function() {
        $( '.mm-trigger' ).removeClass( 'active' );
        slideout.close();

        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function() {
            destroySelect2();

            if ( $(window).width() > 768 ) {
                initializeSelect2();
            }
        }, 250);
    } );

    initializeSelect2();

    $( 'body' ).on( 'edd_cart_billing_address_updated', function() {
        $( '#edd-card-state-wrap .select2' ).remove();
        $( 'select#card_state' ).select2( { width: '100%' });
    } );

    function initializeSelect2() {
        $( 'select#card_state, #billing_country' ).select2( { width: '100%' });
    }

    function destroySelect2() {
        $( 'select#card_state, #billing_country' ).each( function( index, el ) {
            if ( $( el ).data( 'select2' ) ) {
                $( el ).select2('destroy');
            }
        });
    }

    $( '#optin_email_signup_form' ).on( 'submit', function( e ) {
        e.preventDefault();
            var $submit = $( this ).find( 'input[type=submit]' );
            var $notification = $( this ).find( '.notification' );
            $notification.hide();
            $submit.val( "Subscribing...");
            var action = $( this ).attr( 'action' );
            var data = $( this ).serialize();

            if ( !$( this ).find( '#optin_first_name' ).val().replace(/^\s+/g, '').length
                || !$( this ).find( '#optin_last_name' ).val().replace(/^\s+/g, '').length
                || !$( this ).find( '#optin_email' ).val().replace(/^\s+/g, '').length ) {
                $notification.text( "Please fill out all the fields." ).show();
                return false;
            }

            $.ajax({
                url: action,
                type: 'POST',
                data: data,
            } )
            .done( function( response ) {
                if ( response.success === true ) {
                    $notification.text( "Successfully subscribed!" ).show();
                } else {
                    $notification.text( "There was an error subscribing." ).show();
                }
            } )
            .fail( function() {
                $notification.text( "There was an error subscribing." ).show();
            } )
            .always( function() {
                $submit.val( "Subscribe" );
            } );

        return false;
    } );
} );