<style lang="sass">

    .live-screen {
        width: 100%;
        background-size: cover;
        background-position: center center;

        .screen {
            outline: 1px solid transparent; // Helps with anti-aliasing
            background-color: white;
            position: absolute;
            top: 0;
            left: 0;
            box-sizing: border-box;

            &:hover {
                .hover-link {
                    opacity: 1;
                }
            }

            .hover-link {
                opacity: 0;
                background-color: rgba( 0, 0, 0, 0.6 );
                color: white;
                text-align: center;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate( -50%, -50% );
                padding: 1em 2em;
                border-radius: 1000px;
                z-index: 2;
                display: block;
                font-size: 72px;
                text-decoration: none;

                &:hover {
                    background-color: rgba( 0, 0, 0, 0.7 );
                }
            }
        }

        .photo {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 2;
            pointer-events: none;
            width: 100%;
        }

        * {
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
        }
    }
</style>

<template>
    <div class="live-screen" ref="canvas" :style="{ paddingTop: paddingTopPercent }">
        <img :src="image" class="photo">
        <div class="screen" ref="screen" :style="{ width: screenWidth, height: screenHeight }" @mouseenter="pauseGsap" @mouseleave="resumeGsap">
            <slot></slot>
            <a href="#" class="hover-link" id="live-screen-link">View Project</a>
        </div>
    </div>
</template>

<script>
    export default {
        props: [
            'image',
            'full-width',
            'full-height',
            'top-left',
            'top-right',
            'bottom-left',
            'bottom-right',
            'screen-resolution' ],
        computed: {
            /**
             * Get the percentage padding to maintain the proper aspect ratio
             * @return   {string}  a padding percentage
             */
            paddingTop: function() {
                return this.fullHeight * 100 / this.fullWidth;
            },

            /**
             * Get the percentage padding to maintain the proper aspect ratio
             * @return   {string}  a padding percentage
             */
            paddingTopPercent: function() {
                return this.paddingTop + '%';
            },

            /**
             * The width of the screen area
             *
             * @return   {string}  the screen width, in pixels
             */
            screenWidth: function() {
                return this.screenResolution.width + 'px';
            },
            /**
             * The height of the screen area
             *
             * @return   {string}  the screen height, in pixels
             */
            screenHeight: function() {
                return this.screenResolution.height + 'px';
            },

        },
        methods: {

            /**
             * Emit an event to pause GSAP
             */
            pauseGsap() {
                jQuery( 'body' ).trigger( 'pause_gsap' );
            },

            /**
             * Emit an event to pause GSAP
             */
            resumeGsap() {
                jQuery( 'body' ).trigger( 'resume_gsap' );
            },

            /**
             * Convert a pixel coordinate based on the scaled canvas
             *
             * @param  {int}   naturalCoord    the unscaled coordinate
             * @return {int}                   the scaled/relative coordinate
             */
            getRelativeCoord( naturalCoord ) {
                var canvas = this.$refs.canvas;
                var factor = canvas.offsetWidth / this.fullWidth;
                return naturalCoord * factor;
            },

            /**
             * Update the transformation of the screen
             *
             * @return {void}
             */
            updateTransform() {
                var elem = this.$refs.screen;

                // create PerspectiveTransfrom
                var transform = new PerspectiveTransform( elem, this.screenResolution.width, this.screenResolution.height, true );

                transform.topLeft.x = this.getRelativeCoord( this.topLeft.x );
                transform.topLeft.y = this.getRelativeCoord( this.topLeft.y );
                transform.topRight.x = this.getRelativeCoord( this.topRight.x );
                transform.topRight.y = this.getRelativeCoord( this.topRight.y );
                transform.bottomRight.x = this.getRelativeCoord( this.bottomRight.x );
                transform.bottomRight.y = this.getRelativeCoord( this.bottomRight.y );
                transform.bottomLeft.x = this.getRelativeCoord( this.bottomLeft.x );
                transform.bottomLeft.y = this.getRelativeCoord( this.bottomLeft.y );

                // Hide the screen if there was an error
                if( transform.checkError() == 0 ){
                    transform.update();
                    elem.style.display = "block";
                } else {
                    elem.style.display = "none";
                }
            },
        },

        mounted: function() {
            this.updateTransform();
            PerspectiveTransform.useDPRFix = true;

            window.onresize = function() {
                PerspectiveTransform.dpr = window.devicePixelRatio;
                this.updateTransform();

            }.bind( this );
        }
    }
</script>