<style lang="sass">
    $primary-color: #5c4338;
    $secondary-color: #66b52d;

    @media only screen and ( min-width: 768px ) {
        .vue-tabs {
            margin-left: calc( 100vw - 100% );
        }
    }

    .vue-tabs-list {
        list-style-type: none;
        margin: 0;
        padding: 0;
        text-align: center;
        padding-bottom: 1em;

        @media only screen and ( min-width: 768px ) {
            margin-bottom: 2em;
        }
    }

    .vue-tabs li {
        display: inline-block;
    }

    .vue-tab {
        display: block;
        list-style-type: none;
        margin: 0 0.5em;
        font-weight: bold;
        line-height: 1;
        font-size: 1.1rem;
        position: relative;
        text-align: center;
        width: calc( 100% - 1em );

        @media only screen and ( min-width: 768px ) {
            width: auto;
        }

        a {
            cursor: pointer;
            color: #333333;
            text-decoration: none;
            transition: color 30ms ease-out;
            position: relative;
            display: inline-block;
            margin-bottom: 1em;

            @media only screen and ( min-width: 768px ) {
                margin-bottom: 0;
            }

            &::after {
                transition: transform 30ms ease-out;
                content: '';
                display: inline-block;
                position: absolute;
                width: 100%;
                height: 3px;
                left: 0;
                top: 100%;
                transform: translateY( -1px );
                background-color: transparent;
            }
        }


        &.is-active {

            a {
                color: $secondary-color !important;
                font-weight: bold;

                &::after {
                    transform: translateY( 0 );
                    background-color: $primary-color !important;
                }
            }
        }

        &:hover:not(.is-active) {
            a {
                &::after {
                    transform: translateY( 0 );
                    background-color: #E1E1E1 !important;
                }
            }
        }
    }

    .vue-tab-content-wrapper {
        max-width: 800px;
        margin: 0 auto;
        position: relative;
        padding: 0 20px;
    }

    .vue-tab--hidden {
        display: none !important;
    }
</style>

<template>
    <div class="vue-tabs">
        <ul role="tablist" class="vue-tabs-list">
            <li
                v-for="(tab, i) in tabs"
                :key="i"
                :class="{ 'is-active': tab.isActive, 'is-disabled': tab.isDisabled }"
                class="vue-tab"
                role="presentation"
                v-show="tab.isVisible"
            >
                <a v-html="tab.header"
                   :aria-controls="tab.hash"
                   :aria-selected="tab.isActive"
                   @click="selectTab(tab.hash, $event)"
                   :href="tab.hash"
                   :class="{ 'vue-tab--hidden': tab.hidden }"
                   role="tab"
                ></a>
            </li>
        </ul>
        <div class="vue-tab-content-wrapper">
            <slot/>
        </div>
    </div>
</template>

<script>
    import expiringStorage from '../expiringStorage';
    import axios from 'axios';
    import $ from 'jquery';

    export default {
        props: {
            cacheLifetime: {
                default: 5,
            },
            options: {
                type: Object,
                required: false,
                default: () => ({
                    useUrlFragment: true,
                }),
            },
        },
        data: () => ({
            tabs: [],
            activeTabHash: '',
            endpointUrl: ''
        }),
        computed: {
            storageKey() {
                return `vue-tabs-component.cache.${window.location.host}${window.location.pathname}`;
            },
        },
        created() {
            this.tabs = this.$children;
        },
        mounted() {
            window.addEventListener('hashchange', () => this.selectTab(window.location.hash));

            // Below code only runs on first load
            // **********************************

            // If there's an exactly matching tab, load it and return
            if (this.findTab(window.location.hash)) {
                this.selectTab(window.location.hash);
                return;
            }

            // Otherwise, if it's an AJAX endpoint, parse it and get that
            if ( window.location.hash.indexOf( '#endpoint-' ) == 0 ) {
                this.selectTab( '#endpoint' );
                this.endpointUrl = window.location.hash.replace( '#endpoint-', '' );
                this.loadEndpoint();
                return;
            }

            // Otherwise load the last accessed tab
            const previousSelectedTabHash = expiringStorage.get(this.storageKey);
            if (this.findTab(previousSelectedTabHash)) {
                this.selectTab(previousSelectedTabHash);
                return;
            }
            if (this.tabs.length) {
                this.selectTab(this.tabs[0].hash);
            }
        },
        methods: {
            findTab(hash) {
                return this.tabs.find(tab => tab.hash === hash);
            },
            selectTab(selectedTabHash, event) {

                // See if we should store the hash in the url fragment.
                if (event && !this.options.useUrlFragment) {
                    event.preventDefault();
                }

                // Load the AJAX endpoint if required
                if ( selectedTabHash.indexOf( '#endpoint-' ) == 0 ) {
                    this.endpointUrl = selectedTabHash.replace( '#endpoint-', '' );
                    selectedTabHash = '#endpoint';
                    this.loadEndpoint();
                }

                const selectedTab = this.findTab(selectedTabHash);
                if (! selectedTab) {
                    return;
                }

                if (selectedTab.isDisabled) {
                    return;
                }
                this.tabs.forEach(tab => {
                    tab.isActive = (tab.hash === selectedTab.hash);
                });
                this.$emit('changed', { tab: selectedTab });
                this.activeTabHash = selectedTab.hash;
                expiringStorage.set(this.storageKey, selectedTab.hash, this.cacheLifetime);
            },
            loadEndpoint() {
                let that = this;
                let selectedTab = that.findTab( '#endpoint' );
                selectedTab.content = '';

                axios.get( this.endpointUrl )
                    .then(response => {
                        selectedTab.content = response.data;
                    } )
                    .catch(e => {
                        console.log( e );
                    })
            },
            setTabVisible(hash, visible) {
                const tab = this.findTab(hash);
                if (! tab) {
                    return;
                }
                tab.isVisible = visible;
                if (tab.isActive) {
                    // If tab is active, set a different one as active.
                    tab.isActive = visible;
                    this.tabs.every((tab, index, array) => {
                        if (tab.isVisible) {
                            tab.isActive = true;
                            return false;
                        }
                        return true;
                    });
                }
            },
        },
    };
</script>